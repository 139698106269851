$font-family-base: "Mulish";
$font-family-highlight: "Quicksand";

$font-size-xxxs: var(--font-size-xxxs, 0.75rem); // 12px
$font-size-xxs: var(--font-size-xxs, 0.875rem); // 14px
$font-size-xs: var(--font-size-xs, 1rem); // 16px
$font-size-sm: var(--font-size-sm, 1.25rem); // 20px
$font-size-md: var(--font-size-md, 1.5rem); // 24px
$font-size-lg: var(--font-size-lg, 1.75rem); // 28px
$font-size-xl: var(--font-size-xl, 2rem); // 32px
$font-size-xxl: var(--font-size-xxl, 2.25rem); // 36px
$font-size-xxxl: var(--font-size-xxxl, 3rem); // 48px
$font-size-display: var(--font-size-display, 3.5rem); // 56px

$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-semi-bold: 600;
$font-weight-bold: 700;

$spacing-xxxs: 0.25rem;
$spacing-xxs: 0.5rem;
$spacing-xs: 0.75rem;
$spacing-sm: 1rem;
$spacing-md: 1.25rem;
$spacing-lg: 1.5rem;
$spacing-xl: 1.75rem;
$spacing-xxl: 2rem;
$spacing-xxxl: 4rem;

$line-height-xxxs: var(--line-height-xxxs, 1rem); // 16x
$line-height-xxs: var(--line-height-xxs, 1.25rem); // 20x
$line-height-xs: var(--line-height-xs, 1.5rem); // 24x
$line-height-sm: var(--line-height-xs, 1.75rem); // 28x
$line-height-md: var(--line-height-md, 2rem); // 32x
$line-height-lg: var(--line-height-lg, 2.25rem); // 36x
$line-height-xl: var(--line-height-xl, 2.5rem); // 40px
$line-height-xxl: var(--line-height-xxl, 2.75rem); // 44px
$line-height-xxxl: var(--line-height-xxxl, 3.5rem); // 56px
$line-height-display: var(--line-height-display, 4rem); // 64px

$border-radius-sm: var(--border-radius-sm, 0.25rem); //4px
$border-radius-md: var(--border-radius-md, 0.5rem); //8px
$border-radius-lg: var(--border-radius-lg, 1rem); //16px
$border-radius-xxl: var(--border-radius-xxl, 2rem); //32px
$border-radius-pill: var(--border-radius-pill, 99999px);

$border-width-none: var(--border-width-none, 0rem); // 0px
$border-width-hairline: var(--border-width-hairline, 0.0625rem); // 1px
$border-width-thin: var(--border-width-thin, 0.125rem); // 2px
$border-width-thick: var(--border-width-thick, 0.25rem); // 4px
$border-width-heavy: var(--border-width-heavy, 0.5rem); // 8px

$shadow-level-1-down: var(
	--shadow-level-1,
	0px 1px 4px hsla(213, 27%, 84%, 0.4)
); // #cbd5e166

$opacity-level-intense: var(--opacity-level-intense, 0.64);

$max-width-xl: 1280px;
$max-width-lg: 900px;
$max-width-md: 700px;
$max-width-sm: 420px;
