$color-brand-primary-pure: var(
	--color-brand-primary-pure,
	hsl(0, 100%, 67%)
); // #ff5757
$color-brand-primary-up: var(
	--color-brand-primary-up,
	hsl(0, 100%, 97%)
); // #ffeeee
$color-brand-primary-down: var(
	--color-brand-primary-down,
	hsl(0, 100%, 77%)
); // #ff8888
$color-brand-primary-deep: var(
	--color-brand-primary-deep,
	hsl(0, 50%, 46%)
); // #B23B3B

$color-interface-light-pure: var(
	--color-interface-light-pure,
	hsl(0, 0%, 100%)
); // #ffffff
$color-interface-light-up: var(
	--color-interface-light-up,
	hsl(210, 40%, 96%)
); // #f1f5f9
$color-interface-light-up-80: var(
	--color-interface-light-up-80,
	hsla(210, 40%, 96%, 0.8)
); // #f1f5f9
$color-interface-light-down: var(
	--color-interface-light-down,
	hsla(214, 32%, 91%, 1)
); // #e2e8f0
$color-interface-light-deep: var(
	--color-interface-light-deep,
	hsl(218, 36%, 96%)
); // #f0f3f8

$color-interface-dark-pure: var(
	--color-interface-dark-pure,
	hsl(217, 33%, 17%)
); // ##1E293B
$color-interface-dark-up: var(
	--color-interface-dark-up,
	hsl(215, 20%, 65%)
); // #94a3b8
$color-interface-dark-down: var(
	--color-interface-dark-down,
	hsl(215, 16%, 47%)
); // #64748b
$color-interface-dark-deep: var(
	--color-interface-dark-deep,
	hsl(215, 19%, 35%)
); // #475569
$color-interface-dark-deep-40: var(
	--color-interface-dark-deep-40,
	hsla(215, 19%, 35%, 0.4)
);

$color-feedback-positive-pure: var(
	--color-feedback-positive-pure,
	hsl(164, 100%, 36%)
); // #00ba88
$color-feedback-positive-up: var(
	--color-feedback-positive-up,
	hsl(140, 65%, 95%)
); // #ecfbf1
$color-feedback-positive-deep: var(
	--color-feedback-positive-deep,
	hsl(141, 51%, 45%)
); // #38ac61

$color-feedback-warning-pure: var(
	--color-feedback-warning-pure,
	hsl(21, 100%, 69%)
); // #ff985f
$color-feedback-warning-up: var(
	--color-feedback-warning-up,
	hsl(19, 100%, 97%)
); // #fff4ef
$color-feedback-warning-down: var(
	--color-feedback-warning-down,
	hsl(21, 100%, 79%)
); // #ffb992;
$color-feedback-warning-deep: var(
	--color-feedback-warning-deep,
	hsl(21, 86%, 48%)
); // #e55c11

$color-feedback-neutral-pure: var(
	--color-feedback-neutral-pure,
	hsl(200, 100%, 50%)
); // #00aaff;
$color-feedback-neutral-up: var(
	--color-feedback-neutral-up,
	hsl(201, 100%, 95%)
); // #e5f6ff
$color-feedback-neutral-down: hsl(200, 100%, 70%); // #67CCFF
$color-feedback-neutral-deep: hsl(200, 100%, 30%); // #00669A

$color-feedback-negative-deep: var(
	--color-feedback-negative-deep,
	hsl(350, 100%, 40%)
); // #CD0023
$color-feedback-negative-up: var(
	--color-feedback-negative-up,
	hsl(350, 100%, 95%)
); //#FFE8EC

$color-gray-scale-50: var(--color-gray-scale-50, hsl(0, 0%, 96%)); // #F5F5F5
$color-gray-scale-900: var(--color-gray-scale-900, hsl(0, 0%, 20%)); // #333333
